<template>
  <div v-if="bidRules.length === 0">
    No bid rules for this type.
  </div>
  <TargetBidRule
    v-for="ruleEntry in bidRules"
    v-else
    :key="ruleEntry.id"
    :bid-rule-entry="ruleEntry"
  />
</template>

<script>
import TargetBidRule from './TargetBidRule.vue';

export default {
  components: { TargetBidRule },
  props: {
    bidRules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {

    };
  },
};
</script>
