<template>
  <div v-if="datatableTargets === null">
    <ProgressSpinner />
  </div>
  <template v-else>
    <Dialog
      v-model:visible="displayBidRulesDialog"
      :header="dialogBidRuleTitle"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}"
      :style="{width: '80vw'}"
    >
      <TargetBidRules :entries="displayBidRules" />
    </Dialog>
    <div v-if="datatableTargets.length === 0">
      <p>No rules were executed for this ad group.</p>
    </div>
    <DataTable
      v-else
      :loading="loading"
      :value="datatableTargets"
      responsive-layout="scroll"
      :scrollable="true"
      scroll-height="450px"
      scroll-direction="both"
      @sort="sortTable"
    >
      <Column
        field="target.name"
        header="Target/Keyword"
        :sortable="isSortable('target.name')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ slotProps.data.target.name }}
        </template>
      </Column>
      <Column
        v-if="!isSpecificCampaign"
        field="campaign.name"
        header="Campaign"
        :sortable="isSortable('campaignName')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ slotProps.data.campaign.name }}
        </template>
      </Column>
      <Column
        field="adgroup.name"
        header="Ad Group"
        :sortable="isSortable('adGroup')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ slotProps.data.adGroup?.name ?? "x" }}
        </template>
      </Column>
      <Column
        field="target.state"
        header="Status"
      >
        <template #body="slotProps">
          <span :class="'campaign-badge status-' + slotProps.data.target.state">{{ slotProps.data.target.state }}</span>
        </template>
      </Column>
      <Column
        field="executionDate"
        header="Execution date"
        :sortable="isSortable('executionDate')"
        class="target-name"
      >
        <template #body="slotProps">
          {{ getExecutionDate(slotProps.data.entries ) }}
        </template>
      </Column>
      <Column
        field="estimatedAcor"
        header="Bid changes"
      >
        <template #body="slotProps">
          {{ slotProps.data.originalBid }}  <i class="pi pi-arrow-right px-2" />  {{ slotProps.data.finalBid }}
        </template>
      </Column>
      <Column
        field="bidrules"
        header="Applied Rules"
        class="applied-bid-rule flex-grow-column"
      >
        <template
          #body="slotProps"
        >
          <div v-if="slotProps.data.entries?.[0]?.message === 'NO_DATA'">
            No data
          </div>
          <Button
            v-else
            class="p-button-sm p-button-secondary"
            :disabled="displayBidRulesDialog"
            @click="() => handleDisplayBidRulesDialog(slotProps.data.target, slotProps.data.entries)"
          >
            {{ seeMoreText(slotProps.data.entries) }}
          </Button>
        </template>
      </Column>
    </DataTable>
  </template>
</template>

<script>
import dayjs from 'dayjs';
import TargetBidRules from './bidrules/TargetBidRules.vue';
import { formatTargetingType } from '../../../../../utils/reports/targetReportUtils';

const FIELD_SORT_MAP = {
  executionDate: 'createdDate',
};

export default {
  components: {
    TargetBidRules,
  },
  props: {
    loading: {
      type: Boolean,
    },
    isSpecificCampaign: {
      type: Boolean,
    },
    targets: {
      type: Object,
      required: true,
    },
  },
  emits: ['sort'],
  data() {
    return {
      displayName: null,
      displayBidRulesDialog: false,
      displayBidRules: null,
    };
  },
  computed: {
    dialogBidRuleTitle() {
      return `Bid rules for ${this.displayName}`;
    },
    datatableTargets() {
      return (this.targets ?? []).map(this.mapTargets);
    },
  },
  methods: {
    isSortable(prop) {
      return !!FIELD_SORT_MAP[prop];
    },
    seeMoreText(entries) {
      if (entries.length === 1) {
        return 'See bidrule';
      }
      return `See ${entries.length} bidrules`;
    },
    mapTargets(target) {
      const datatableTarget = JSON.parse(JSON.stringify(target));
      datatableTarget.target.name = this.formatTargetText(target.target);
      datatableTarget.executionDate = this.getNonFormattedExecutionDate(target.entries);
      datatableTarget.originalBid = target.entries?.[0]?.change?.originalBid;
      datatableTarget.finalBid = target.entries?.[0]?.change?.finalBid;

      return datatableTarget;
    },
    handleDisplayBidRulesDialog(target, entries) {
      this.displayBidRulesDialog = true;
      this.displayBidRules = entries.filter((entry) => entry.targetId === (target.keywordId ?? target.targetId));
      this.displayName = this.formatTargetText(target);
    },
    formatTargetText(target) {
      return formatTargetingType(target);
    },
    getExecutionDate(entries) {
      if (entries.length === 0) {
        return 'No data';
      }
      const firstEntry = entries[0];
      const date = firstEntry.executionDate ?? firstEntry.createdDate;

      return date ? dayjs(date).format('YYYY-MM-DD') : 'No data';
    },
    sortTable(event) {
      this.$emit('sort', { field: FIELD_SORT_MAP[event.sortField], dir: event.sortOrder === 1 ? 'asc' : 'desc' });
    },
    getNonFormattedExecutionDate(entries) {
      if (entries.length === 0) {
        return null;
      }
      const firstEntry = entries[0];
      if (!firstEntry.executionDate) {
        return null;
      }
      return new Date(entries[0].executionDate);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
<style>
.target-name {
  min-width: 200px;
}

th, td {
  width: 150px;
}
</style>
