<template>
  <SelectButton
    v-model="selectedRuleType"
    :options="ruleTypes"
    option-label="name"
    class="pb-3"
  />
  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'INCREASE'"
      :bid-rules="increaseRules"
    />
  </keep-alive>

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'DECREASE'"
      :bid-rules="decreaseRules"
    />
  </keep-alive>

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'PAUSE_ENABLED'"
      :bid-rules="pauseEnableRules"
    />
  </keep-alive>

  <keep-alive>
    <TargetBidRulesList
      v-if="selectedRuleType.value === 'AUTOMATE_BID_OPTIMIZATION'"
      :bid-rules="autoRules"
    />
  </keep-alive>
</template>

<script>
import TargetBidRulesList from './list/TargetBidRulesList.vue';

const RULE_TYPES = ({
  increase, decrease, pause, automate,
}) => [
  increase && { name: `Increase (${increase})`, value: 'INCREASE' },
  decrease && { name: `Decrease (${decrease})`, value: 'DECREASE' },
  pause && { name: `Pause/Enable (${pause})`, value: 'PAUSE_ENABLED' },
  automate && { name: `Automate (${automate})`, value: 'AUTOMATE_BID_OPTIMIZATION' },
].filter(Boolean);

export default {
  components: { TargetBidRulesList },
  props: {
    entries: {
      type: Object,
      required: true,
    },
  },
  data() {
    const increaseRules = this.entries.filter((entry) => entry.bidRule.result.type === 'INCREASE');
    const decreaseRules = this.entries.filter((entry) => entry.bidRule.result.type === 'DECREASE');
    const pauseEnableRules = this.entries.filter((entry) => ['PAUSE', 'RE_ENABLE'].includes(entry.bidRule.result.type));
    const autoRules = this.entries.filter((entry) => entry.bidRule.result.type === 'AUTOMATE_BID_OPTIMIZATION');

    const ruleTypes = RULE_TYPES({
      increase: increaseRules.length,
      decrease: decreaseRules.length,
      pause: pauseEnableRules.length,
      automate: autoRules.length,
    });

    return {
      increaseRules,
      decreaseRules,
      pauseEnableRules,
      autoRules,
      ruleTypes,
      selectedRuleType: ruleTypes[0],
    };
  },
};
</script>
