<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else>
    <h2 class="text-2xl">
      Bidding Rules History: {{ selectedProfile.name }} - {{ selectedProfile.countryCode }}
    </h2>

    <p>
      Filter by Campaign: <Dropdown
        v-model="selectedCampaign"
        show-clear
        :options="campaigns"
        filter
        option-label="name"
        placeholder="Select specific campaign"
      />
    </p>

    <TargetsTable
      :targets="keywords"
      :loading="pageLoading"
      :is-specific-campaign="!!selectedCampaign"
      @sort="refetch"
    />

    <Paginator
      v-show="!pageLoading"
      v-model:first="currentPage"
      :rows="PAGE_SIZE"
      :total-records="totalKeywords"
      :disabled="pageLoading"
      @page="refetch"
    />
  </template>
</template>

<script>
// TODO: Improve v-show with disabled state
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import A2CAClient from '../../../../../api/a2caAuthorizedClient';
import TargetsTable from './TargetsTable.vue';

const PAGE_SIZE = 15;
export default {
  components: { TargetsTable },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  setup(props) {
    const currentPage = ref(0);
    const sort = ref('createdDate,desc');
    const route = useRoute();
    const client = new A2CAClient(route.query);
    const pageLoading = ref(false);
    const loading = ref(true);
    const keywords = ref(null);
    const campaigns = ref([]);
    const selectedCampaign = ref(null);
    const totalKeywords = ref(0);

    watch(selectedCampaign, async () => {
      pageLoading.value = true;

      const history = await client.getLatestBidRuleHistory({
        advertisingScopeId: props.selectedProfile.profileId,
        region: props.selectedProfile.region,
        page: { page: 0, size: PAGE_SIZE, sort: sort.value },
        campaignId: selectedCampaign.value?.campaignId,
      });
      keywords.value = history.content;
      totalKeywords.value = history.totalElements;
      pageLoading.value = false;
    });
    const refetch = async ({ page, field, dir }) => {
      pageLoading.value = true;

      if (field && dir) {
        sort.value = `${field},${dir}`;
      }

      const history = await client.getLatestBidRuleHistory({
        advertisingScopeId: props.selectedProfile.profileId,
        region: props.selectedProfile.region,
        page: { page: page ?? 0, size: PAGE_SIZE, sort: sort.value },
        campaignId: selectedCampaign.value?.campaignId,
      });

      if (!page) {
        currentPage.value = 0;
      }

      keywords.value = history.content;
      totalKeywords.value = history.totalElements;

      pageLoading.value = false;
    };

    onMounted(async () => {
      loading.value = true;
      const campaignsResult = await client.getCampaignsInHistory(props.selectedProfile.profileId, props.selectedProfile.region);
      campaigns.value = campaignsResult;

      const latestHistory = await client.getLatestBidRuleHistory({
        advertisingScopeId: props.selectedProfile.profileId,
        region: props.selectedProfile.region,
        page: { page: 0, size: PAGE_SIZE },
      });

      keywords.value = latestHistory.content;
      totalKeywords.value = latestHistory.totalElements;

      loading.value = false;
    });

    return {
      refetch, pageLoading, loading, keywords, totalKeywords, currentPage, PAGE_SIZE, campaigns, selectedCampaign,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
